<template>
  <div>
    <notifications group="notification" position="top right" />
    <div v-if="isOwnersLoaded" class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title">
          <span class="card-label font-weight-bolder text-dark">Owners</span>
        </h3>
        <div class="card-toolbar">
          <!--begin::Button-->
          <router-link
            to="/loyalty/owners/new"
            class="btn btn-primary font-weight-bolder"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg src="media/svg/icons/Clothes/Briefcase.svg" /> </span
            >Add New
          </router-link>
        </div>
      </div>

      <div class="card-body py-0">
        <div class="table-responsive">
          <table
            class="table table-head-custom table-vertical-center table-head-bg"
          >
            <thead>
              <tr class="text-left text-uppercase">
                <th class="pl-6">
                  <span @click="orderOwnersByName" class="cursor-pointer">
                    <span
                      class="pt-2"
                      :class="{ 'text-primary': nameOrder.isActive }"
                    >
                      Name</span
                    >
                    <span
                      class="svg-icon svg-icon-sm pl-2"
                      :class="{
                        'svg-icon-primary': nameOrder.isActive,
                      }"
                    >
                      <inline-svg
                        v-if="nameOrder.query === 'asc'"
                        src="media/svg/icons/Navigation/Down-2.svg"
                      />
                      <inline-svg
                        v-else
                        src="media/svg/icons/Navigation/Up-2.svg"
                      />
                    </span>
                  </span>
                </th>
                <th>
                  <span @click="orderOwnersByTotalCards" class="cursor-pointer">
                    <span
                      class="pt-2"
                      :class="{
                        'text-primary': totalCardsNumberOrder.isActive,
                      }"
                    >
                      Total cards</span
                    >
                    <span
                      class="svg-icon svg-icon-sm pl-2"
                      :class="{
                        'svg-icon-primary': totalCardsNumberOrder.isActive,
                      }"
                    >
                      <inline-svg
                        v-if="totalCardsNumberOrder.query === 'asc'"
                        src="media/svg/icons/Navigation/Down-2.svg"
                      />
                      <inline-svg
                        v-else
                        src="media/svg/icons/Navigation/Up-2.svg"
                      />
                    </span>
                  </span>
                </th>
                <th>
                  <span
                    @click="orderOwnersByActiveCards"
                    class="cursor-pointer"
                  >
                    <span
                      class="pt-2"
                      :class="{
                        'text-primary': totalCardsActiveOrder.isActive,
                      }"
                    >
                      Active cards</span
                    >
                    <span
                      class="svg-icon svg-icon-sm pl-2"
                      :class="{
                        'svg-icon-primary': totalCardsActiveOrder.isActive,
                      }"
                    >
                      <inline-svg
                        v-if="totalCardsActiveOrder.query === 'asc'"
                        src="media/svg/icons/Navigation/Down-2.svg"
                      />
                      <inline-svg
                        v-else
                        src="media/svg/icons/Navigation/Up-2.svg"
                      />
                    </span>
                  </span>
                </th>
                <th>
                  <span
                    @click="orderOwnersByVerifiedCards"
                    class="cursor-pointer"
                  >
                    <span
                      class="pt-2"
                      :class="{
                        'text-primary': totalCardsVerifiedOrder.isActive,
                      }"
                    >
                      Verified cards</span
                    >
                    <span
                      class="svg-icon svg-icon-sm pl-2"
                      :class="{
                        'svg-icon-primary': totalCardsVerifiedOrder.isActive,
                      }"
                    >
                      <inline-svg
                        v-if="totalCardsVerifiedOrder.query === 'asc'"
                        src="media/svg/icons/Navigation/Down-2.svg"
                      />
                      <inline-svg
                        v-else
                        src="media/svg/icons/Navigation/Up-2.svg"
                      />
                    </span>
                  </span>
                </th>
                <th class="text-right pr-6">Options</th>
              </tr>
            </thead>
            <tr v-if="!isOwnersLoaded">
              <td colspan="5" style="width: 100%; text-align: center">
                <b-spinner
                  class="mt-5"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </td>
            </tr>
            <tbody v-else>
              <tr v-for="(owner, key) in owners" :key="key">
                <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                  <router-link
                    :to="'/loyalty/owners/edit/' + owner.id"
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >{{ owner.name }}</router-link
                  >
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  <span class="badge badge-primary">{{
                    owner.totalCards
                  }}</span>
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  <span class="badge badge-primary">{{
                    owner.activeCards
                  }}</span>
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  <span class="badge badge-primary">{{
                    owner.verifiedCards
                  }}</span>
                </td>
                <td
                  class="text-right pr-6"
                  :class="{ 'border-top-0': key === 0 }"
                >
                  <button
                    @click="toggleOwnerCardsModal(owner.name)"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg
                        src="media/svg/icons/Text/Align-justify.svg"
                      />
                    </span>
                  </button>
                  <router-link
                    :to="'/loyalty/owners/edit/' + owner.id"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg
                        src="media/svg/icons/General/Settings-1.svg"
                      />
                    </span>
                  </router-link>
                  <button
                    @click="deleteEntity(owner.id)"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/General/Trash.svg" />
                    </span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-modal
          id="owner_cards_modal"
          size="xl"
          hide-header
          hide-footer
          centered
        >
          <div>
            <button
              class="btn btn-light-primary btn-block mb-3"
              @click="$bvModal.hide('owner_cards_modal')"
            >
              Close
            </button>
            <div>
              <div class="table-responsive">
                <table
                  class="table table-head-custom table-vertical-center table-head-bg"
                >
                  <thead>
                    <tr class="text-left text-uppercase">
                      <th class="px-6">
                        <span
                          @click="orderCardBySerialNumber"
                          class="cursor-pointer"
                        >
                          <span
                            class="pt-2"
                            :class="{
                              'text-primary': cardSerialNumberOrder.isActive,
                            }"
                          >
                            Serial number</span
                          >
                          <span
                            class="svg-icon svg-icon-sm pl-2"
                            :class="{
                              'svg-icon-primary':
                                cardSerialNumberOrder.isActive,
                            }"
                          >
                            <inline-svg
                              v-if="cardSerialNumberOrder.query === 'asc'"
                              src="media/svg/icons/Navigation/Down-2.svg"
                            />
                            <inline-svg
                              v-else
                              src="media/svg/icons/Navigation/Up-2.svg"
                            />
                          </span>
                        </span>
                      </th>
                      <th>Owner</th>
                      <th>
                        <span @click="orderCardBySaldo" class="cursor-pointer">
                          <span
                            class="pt-2"
                            :class="{
                              'text-primary': cardSaldoOrder.isActive,
                            }"
                          >
                            Saldo</span
                          >
                          <span
                            class="svg-icon svg-icon-sm pl-2"
                            :class="{
                              'svg-icon-primary': cardSaldoOrder.isActive,
                            }"
                          >
                            <inline-svg
                              v-if="cardSaldoOrder.query === 'asc'"
                              src="media/svg/icons/Navigation/Down-2.svg"
                            />
                            <inline-svg
                              v-else
                              src="media/svg/icons/Navigation/Up-2.svg"
                            />
                          </span>
                        </span>
                      </th>
                      <th class="text-right pr-6">
                        <span
                          @click="orderCardByIsVerified"
                          class="cursor-pointer"
                        >
                          <span
                            class="pt-2"
                            :class="{
                              'text-primary': cardIsVerifiedOrder.isActive,
                            }"
                          >
                            Is verified</span
                          >
                          <span
                            class="svg-icon svg-icon-sm pl-2"
                            :class="{
                              'svg-icon-primary': cardIsVerifiedOrder.isActive,
                            }"
                          >
                            <inline-svg
                              v-if="cardIsVerifiedOrder.query === 'asc'"
                              src="media/svg/icons/Navigation/Down-2.svg"
                            />
                            <inline-svg
                              v-else
                              src="media/svg/icons/Navigation/Up-2.svg"
                            />
                          </span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(card, key) in ownerCards" :key="key">
                      <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                        <router-link
                          :to="'/loyalty/cards/edit/' + card.id"
                          class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                          >{{ card.serialNumber }}</router-link
                        >
                      </td>
                      <td :class="{ 'border-top-0': key === 0 }">
                        <span>{{ card.owner.name }}</span>
                      </td>
                      <td :class="{ 'border-top-0': key === 0 }">
                        <span>{{ card.saldo | currencyFormat }}</span>
                      </td>
                      <td
                        class="text-right pr-6"
                        :class="{ 'border-top-0': key === 0 }"
                      >
                        <span
                          v-if="card.isVerified"
                          class="label label-rounded label-primary px-5"
                          >{{ $t('GENERAL.YES') }}</span
                        >
                        <span
                          v-else
                          class="label label-rounded label-danger px-5"
                          >No</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import axios from 'axios'

export default {
  name: 'LoyaltyOwnerList',
  data() {
    return {
      owners: [],
      isOwnersLoaded: false,
      ownerCards: [],
      isOwnerCardsLoaded: false,
      nameOrder: {
        query: 'desc',
        isActive: false,
      },
      totalCardsNumberOrder: {
        query: 'asc',
        isActive: false,
      },
      totalCardsActiveOrder: {
        query: 'asc',
        isActive: false,
      },
      totalCardsVerifiedOrder: {
        query: 'asc',
        isActive: false,
      },

      cardSerialNumberOrder: {
        query: 'desc',
        isActive: false,
      },
      cardOwnerOrder: {
        query: 'desc',
        isActive: false,
      },
      cardSaldoOrder: {
        query: 'desc',
        isActive: false,
      },
      cardIsVerifiedOrder: {
        query: 'desc',
        isActive: false,
      },
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Loyalty All Owners', route: '' },
    ])

    this.getOwners('https://loyalty.vendingtycoon.eu/api/v1/owners').then(
      ({ data }) => {
        this.owners = [...data['hydra:member']]
        this.isOwnersLoaded = true
      }
    )
  },
  methods: {
    async getOwners(endpoint) {
      this.owners = []
      this.isOwnersLoaded = false

      try {
        return axios.get(endpoint)
      } catch (e) {
        throw new Error(e)
      }
    },
    deleteEntity(ownerId) {
      axios
        .delete('https://loyalty.vendingtycoon.eu/api/v1/owners/' + ownerId)
        .then(() => {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Owner successful removed from database',
          })

          this.getOwners('https://loyalty.vendingtycoon.eu/api/v1/owners').then(
            ({ data }) => {
              this.owners = [...data['hydra:member']]
              this.isOwnersLoaded = true
            }
          )
        })
    },
    toggleOwnerCardsModal(ownerName) {
      this.ownerCards = []
      this.isOwnerCardsLoaded = false

      axios
        .get(
          `https://loyalty.vendingtycoon.eu/api/v1/cards?owner.name=${ownerName}`
        )
        .then(({ data }) => {
          this.ownerCards = data['hydra:member']
          this.isOwnerCardsLoaded = true
          this.$root.$emit('bv::show::modal', 'owner_cards_modal')
        })
    },

    // owners
    orderOwnersByName() {
      this.nameOrder.isActive = true

      if (this.nameOrder.query === 'asc') this.nameOrder.query = 'desc'
      else this.nameOrder.query = 'asc'

      if (this.nameOrder.query === 'asc') {
        this.owners.sort((a, b) => a.name.localeCompare(b.name))
      } else {
        this.owners.sort((a, b) => b.name.localeCompare(a.name))
      }
    },
    orderOwnersByTotalCards() {
      this.totalCardsNumberOrder.isActive = true

      if (this.totalCardsNumberOrder.query === 'asc')
        this.totalCardsNumberOrder.query = 'desc'
      else this.totalCardsNumberOrder.query = 'asc'

      if (this.totalCardsNumberOrder.query === 'asc') {
        this.owners = this.owners.sort((a, b) => a.totalCards - b.totalCards)
      } else {
        this.owners = this.owners.sort((a, b) => b.totalCards - a.totalCards)
      }
    },
    orderOwnersByActiveCards() {
      this.totalCardsActiveOrder.isActive = true

      if (this.totalCardsActiveOrder.query === 'asc')
        this.totalCardsActiveOrder.query = 'desc'
      else this.totalCardsActiveOrder.query = 'asc'

      if (this.totalCardsActiveOrder.query === 'asc') {
        this.owners.sort((a, b) => a.activeCards - b.activeCards)
      } else {
        this.owners.sort((a, b) => b.activeCards - a.activeCards)
      }
    },
    orderOwnersByVerifiedCards() {
      this.totalCardsVerifiedOrder.isActive = true

      if (this.totalCardsVerifiedOrder.query === 'asc')
        this.totalCardsVerifiedOrder.query = 'desc'
      else this.totalCardsVerifiedOrder.query = 'asc'

      if (this.totalCardsVerifiedOrder.query === 'asc') {
        this.owners.sort((a, b) => a.verifiedCards - b.verifiedCards)
      } else {
        this.owners.sort((a, b) => b.verifiedCards - a.verifiedCards)
      }
    },

    // cards
    orderCardBySerialNumber() {
      this.cardSerialNumberOrder.isActive = true

      if (this.cardSerialNumberOrder.query === 'asc')
        this.cardSerialNumberOrder.query = 'desc'
      else this.cardSerialNumberOrder.query = 'asc'

      if (this.cardSerialNumberOrder.query === 'asc') {
        this.ownerCards.sort((a, b) =>
          a.serialNumber.localeCompare(b.serialNumber)
        )
      } else {
        this.ownerCards.sort((a, b) =>
          b.serialNumber.localeCompare(a.serialNumber)
        )
      }
    },
    orderCardBySaldo() {
      this.cardSaldoOrder.isActive = true

      if (this.cardSaldoOrder.query === 'asc')
        this.cardSaldoOrder.query = 'desc'
      else this.cardSaldoOrder.query = 'asc'

      if (this.cardSaldoOrder.query === 'asc') {
        this.ownerCards.sort((a, b) => a.saldo - b.saldo)
      } else {
        this.ownerCards.sort((a, b) => b.saldo - a.saldo)
      }
    },
    orderCardByIsVerified() {
      this.cardIsVerifiedOrder.isActive = true

      if (this.cardIsVerifiedOrder.query === 'asc')
        this.cardIsVerifiedOrder.query = 'desc'
      else this.cardIsVerifiedOrder.query = 'asc'

      if (this.cardSaldoOrder.query === 'asc') {
        this.ownerCards.sort((a, b) => a.isVerified - b.isVerified)
      } else {
        this.ownerCards.sort((a, b) => b.isVerified - a.isVerified)
      }
    },
  },
}
</script>
